<template>
  <v-list-group
    v-model="tournament.active"
    active-class="color3--text"
    append-icon="fas fa-angle-down"
    class="tList"
  >
    <template v-slot:activator>
      <v-list-item-title class="pa-0">{{tournament.name}}</v-list-item-title>
    </template>
    <!-- Home -->
      <v-list-item
        :to="{ name: view.admin ? 'tournament-admin' : 'tournament-brochure', params: {tournamentId: tournament.id} }"
        router
        exact
        exact-active-class="color3--text"
      >
        <v-list-item-content>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    <!-- check-in -->
      <v-list-item
         v-if="view.admin"
        :to="{ name: 'tournament-teams', params: {tournamentId: tournament.id} }"
        router
        exact
        exact-active-class="color3--text"
      >
        <v-list-item-content>
          <v-list-item-title>Check-in</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    <!-- Players -->
      <v-list-item
        v-if="tournament.isShowcase"
        :to="{ name: 'tournament-players', params: {tournamentId: tournament.id} }"
        router
        exact
        exact-active-class="color3--text"
      >
        <v-list-item-content>
          <v-list-item-title>Players</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    <!-- Courts -->
      <v-list-item
        v-if="tournament.isShowcase && view.adminAndRefs"
        :to="{ name: 'tournament-courts', params: {tournamentId: tournament.id} }"
        router
        exact
        exact-active-class="color3--text"
      >
        <v-list-item-content>
          <v-list-item-title v-if="view.adminAndRefs">Court Manager</v-list-item-title>
          <v-list-item-title v-else>Courts</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    <!-- Divisions -->
    <template v-for="division in publicDivisions.filter(f => view.admin || f.isTryout || f.showTeams)">
      <!-- Division Home -->
        <v-list-item
          :key="division.id"
          router
          :to="{name: home, params: {tournamentId: tournament.id, divisionId: division.id}}"
          exact
          exact-active-class="color3--text"
        >
          <v-list-item-content>
            <v-list-item-title v-if="division.loading">
              <v-progress-linear :indeterminate="true" color="color3"></v-progress-linear>
            </v-list-item-title>
            <v-list-item-title v-else>
              {{ division.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      <template v-if="!division.loading">
        <!-- TEAMS -->
          <v-list-item
            v-if="view.admin || division.showTeams"
            :to="{name: 'division-teams', params: {tournamentId: tournament.id, divisionId: division.id}}"
            :key="`teams_${division.id}`"
            router
            exact
            exact-active-class="color3--text"
          >
            <v-list-item-action class="mr-2"></v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{division.teamLabel}}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip small :color="chipColor">
                {{division.teamCount}}
              </v-chip>
            </v-list-item-action>
          </v-list-item>
        <!-- WAITLIST -->
          <v-list-item
            v-if="division.waitlistTeams.length"
            :to="{name: 'division-waitlist', params: {tournamentId: tournament.id, divisionId: division.id}}"
            :key="`waitlist_${division.id}`"
            router
            exact
            exact-active-class="color3--text"
          >
            <v-list-item-action class="mr-2"></v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Waitlist
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="view.admin || division.showTeams">
              <v-chip small :color="chipColor">
                {{division.waitlistTeams.length}}
              </v-chip>
            </v-list-item-action>
          </v-list-item>

      </template>
    </template>

  </v-list-group>
</template>

<script>
import { mapGetters } from 'vuex'
import { getHsp } from '@/classes/Dark'

export default {
  computed: {
    ...mapGetters(['user', 'tournament', 'view', 'color2IsDark', 'theme']),
    chipColor () {
      const hsp1 = getHsp(this.theme.color2)
      const hsp2 = getHsp(this.theme.color2Text)
      return hsp1 < hsp2 ? 'color2 color2Text--text' : 'color2Text color2--text'
    },
    publicDivisions () {
      var divs = this.tournament && this.tournament.publicDivisions
      var jProps = this.tournament && this.tournament.jProps && this.tournament.jProps.divisions
      return divs && jProps ? divs.filter(f => !(jProps[f.id] && jProps[f.id].hide)) : divs
    },
    home () {
      return this.view.admin ? 'division-home' : 'division-teams'
    }
  }
}
</script>
<style>
.tList .v-list-group__header {
  padding-left: 8px !important;
  padding-right: 2px !important;
}
</style>
